import type { MetaInfo } from 'vue-meta';
import {
  getPrice as getProductPrice,
} from '~/modules/catalog/product/getters/productGetters';

export const getMetaInfo = (page: any, isNoIndex: any = false, defaultImage: any = '', url: any = '', home: any = '', type: any = ''): MetaInfo => {
  if (!page) {
    return null;
  }

  const seoTags: MetaInfo = {
    meta: [],
    script: [],
    link: [],
  };

  if (page?.meta_title || page?.title || page?.name) {
    seoTags.title = page?.meta_title || page?.title || page?.name;

    seoTags.meta.push({
      hid: 'og:title',
      name: 'og:title',
      property: 'og:title',
      content: page?.meta_title || page?.title || page?.name,
    });
  }
  if (page?.meta_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page?.meta_description,
    });

    seoTags.meta.push({
      hid: 'og:description',
      name: 'og:description',
      property: 'og:description',
      content: page?.meta_description,
    });
  }
  else {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: '',
    });

    seoTags.meta.push({
      hid: 'og:description',
      name: 'og:description',
      property: 'og:description',
      content: '',
    });
  }
  if (page?.meta_keyword) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword,
    });
  }
  if (page?.thumbnail) {
    seoTags.meta.push({
      hid: 'og:image',
      name: 'og:image',
      property: 'og:image',
      content: page?.thumbnail.url,
    });
  }else if(defaultImage != ''){
    seoTags.meta.push({
      hid: 'og:image',
      name: 'og:image',
      property: 'og:image',
      content: defaultImage,
    });
  }
  if(url != ''){
    seoTags.meta.push({
      hid: 'og:url',
      name: 'og:url',
      property: 'og:url',
      content: url,
    });
  }
  if (isNoIndex) {
    seoTags.meta.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  if(url != '') {
    seoTags.link.push({
      rel: 'canonical',
      href: url
    });
  }

  seoTags.meta.push({
    hid: 'og:site_name',
    name: 'og:site_name',
    property: 'og:site_name',
    content: 'Needstore',
  });

  if(type != '') {
    if(type == 'product') {
      seoTags.script.push({
        innerHTML: JSON.stringify(
          {
            "@context":"https://schema.org",
            "@graph": [
              {
                "@type":"WebSite",
                "@id":"'https://www.needstore.it#website",
                "url":"'https://www.needstore.it",
                "name":"Needstore",
                "description":page?.meta_description,
                "inLanguage":"it-IT",
                "publisher": {
                  "@id":"https://www.needstore.it#organization"
                }
              },
              {
                "@type":"Organization",
                "@id":"https://www.needstore.it#organization",
                "name":"Needstore",
                "url":"https://www.needstore.it",
                "logo":{
                  "@type":"ImageObject",
                  "@id":"https://www.needstore.it#organizationLogo",
                  "url":defaultImage,
                  "width":230,
                  "height":68,
                  "caption":home?.meta_title
                },
                "image":{
                  "@id":"https://www.needstore.it#organizationLogo"
                },
                "sameAs":[
                  "https://www.facebook.com/need.it.s.all.you.need",
                  "https://www.instagram.com/need_italia/"
                ],
                "contactPoint":{
                  "@type":"ContactPoint",
                  "telephone":"+393713862596",
                  "contactType":"tech support"
                }
              },
              {
                "@type":"BreadcrumbList",
                "@id":url+"#breadcrumblist",
                "itemListElement":[
                  {
                    "@type":"ListItem",
                    "@id":"https://www.needstore.it#listItem",
                    "position":1,
                    "item":{
                      "@type":"WebPage",
                      "@id":"https://www.needstore.it",
                      "name":"Home",
                      "description":home?.meta_title,
                      "url":"https://www.needstore.it"
                    },
                    "nextItem":url+"#listItem"
                  },
                  {
                    "@type":"ListItem",
                    "@id":url+"#listItem",
                    "position":2,
                    "item":{
                      "@type":"WebPage",
                      "@id":url,
                      "name":page?.title,
                      "description":page?.meta_description,
                      "url":url
                    },
                    "previousItem":"https://www.needstore.it#listItem"
                  }
                ]
              },
              {
                "@type":"ItemPage",
                "@id":url+"#itempage",
                "url":url,
                "name":page?.meta_title,
                "description":page?.meta_description,
                "inLanguage":"it-IT",
                "breadcrumb":{
                  "@id":url+"#breadcrumblist"
                },
                "image":{
                  "@type":"ImageObject",
                  "@id":"https://www.needstore.it#mainImage",
                  "url":page?.thumbnail?.url,
                  "width":500,
                  "height":390,
                  "caption":page?.title
                },
                "primaryImageOfPage":{
                  "@id":url+"#mainImage"},
              },
              {
                "@type":"Product",
                "@id":url+"#product",
                "name":page?.title,
                "url":url,
                "description":page?.short_description?.html,
                "image":page?.thumbnail?.url,
                "sku":page?.sku,
                "offers":[
                  {
                    "@type":"Offer",
                    "price":getProductPrice(page).regular,
                    "priceSpecification":{
                      "price":getProductPrice(page).regular,
                      "priceCurrency":"EUR",
                      "valueAddedTaxIncluded":"false"
                    },
                    "priceCurrency":"EUR",
                    "availability":"http://schema.org/InStock",
                    "url":url,
                    "seller":{
                      "@type":"Organization",
                      "name":"Needstore",
                      "url":"https://www.needstore.it"
                    },
                  }
                ],
                "aggregateRating":{
                  "@type":"AggregateRating",
                  "ratingValue":page?.rating_summary,
                  "reviewCount":page?.review_count
                }
              }
            ]
          }
        ),
        type: 'application/ld+json'
      });
    }
    else if(type == 'category'){
      seoTags.script.push({
        innerHTML: JSON.stringify(
          {
            "@context":"https://schema.org",
            "@graph":[
              {
                "@type":"WebSite",
                "@id":"https://www.needstore.it#website",
                "url":"https://www.needstore.it",
                "name":"Needstore",
                "description":home?.meta_title,
                "inLanguage":"it-IT",
                "publisher":{
                  "@id":"https://www.needstore.it#organization"
                }
              },
              {
                "@type":"Organization",
                "@id":"https://www.needstore.it#organization",
                "name":"Needstore",
                "url":"https://www.needstore.it",
                "logo":{
                  "@type":"ImageObject",
                  "@id":"https://www.needstore.it#organizationLogo",
                  "url":defaultImage,
                  "width":230,
                  "height":68,
                  "caption":home?.meta_title
                },"image":{
                  "@id":"https://www.needstore.it#organizationLogo"
                  },
                  "sameAs":[
                    "https://www.facebook.com/need.it.s.all.you.need",
                    "https://www.instagram.com/need_italia/"
                  ],
                "contactPoint":{
                  "@type":"ContactPoint",
                  "telephone":"+393713862596",
                  "contactType":"tech support"
                }
              },
              {
                "@type":"BreadcrumbList",
                "@id":url+"#breadcrumblist",
                "itemListElement":[
                  {
                    "@type":"ListItem",
                    "@id":"https://www.needstore.it#listItem",
                    "position":1,
                    "item":{
                      "@type":"WebPage",
                      "@id":"https://www.needstore.it",
                      "name":"Home",
                      "description":home?.meta_description,
                      "url":"https://www.needstore.it"
                    },
                    "nextItem":url+"#listItem"
                  },
                  {
                    "@type":"ListItem",
                    "@id":url+"#listItem",
                    "position":2,
                    "item":{
                      "@type":"WebPage",
                      "@id":url,
                      "name":page.title,
                      "description":page?.meta_description,
                      "url":url
                    },
                    "previousItem":"https://www.needstore.it#listItem"
                  }
                ]
              },
              {
                "@type":"CollectionPage",
                "@id":url+"#collectionpage",
                "url":url,
                "name":page?.meta_title,
                "description":page?.meta_description,
                "inLanguage":"it-IT",
                "isPartOf":{
                  "@id":"https://www.needstore.it#website"
                },"breadcrumb":{
                    "@id":url+"#breadcrumblist"
                  }
              }
            ]
          }
        ),
        type: 'application/ld+json'
      });
    }
    else if(type == 'home'){
      seoTags.script.push({
        innerHTML: JSON.stringify(
          {
            "@context":"https://schema.org",
            "@graph":[
              {
                "@type":"WebSite",
                "@id":"https://www.needstore.it#website",
                "url":"https://www.needstore.it",
                "name":"Needstore",
                "description":page?.meta_description,
                "inLanguage":"it-IT",
                "publisher":{
                  "@id":"https://www.needstore.it#organization"
                }
              },
              {
                "@type":"Organization",
                "@id":"https://www.needstore.it#organization",
                "name":"JustBob",
                "url":"https://www.needstore.it",
                "logo":{
                  "@type":"ImageObject",
                  "@id":"https://www.needstore.it#organizationLogo",
                  "url":defaultImage,
                  "width":230,"height":68,
                  "caption":page?.meta_title
                },
                "image":{
                  "@id":"https://www.needstore.it#organizationLogo"
                },
                "sameAs":[
                  "https://www.facebook.com/need.it.s.all.you.need",
                  "https://www.instagram.com/need_italia/"
                ],
                "contactPoint":{
                  "@type":"ContactPoint",
                  "telephone":"+393713862596",
                  "contactType":"tech support"
                }
              },
              {
                "@type":"BreadcrumbList",
                "@id":"https://www.needstore.it#breadcrumblist",
                "itemListElement":[
                  {
                    "@type":"ListItem",
                    "@id":"https://www.needstore.it#listItem",
                    "position":1,
                    "item":{
                      "@type":"WebPage",
                      "@id":"https://www.needstore.it",
                      "name":"Home",
                      "description":page?.meta_description,
                      "url":"https://www.needstore.it"
                    }
                  }
                ]
              },
              {
                "@type":"WebPage",
                "@id":"https://www.needstore.it#webpage",
                "url":"https://www.needstore.it",
                "name":page?.meta_title,
                "description":page?.meta_description,
                "inLanguage":"it-IT",
                "isPartOf":{
                  "@id":"https://www.needstore.it#website"
                },
                "breadcrumb":{
                  "@id":"https://www.needstore.it#breadcrumblist"
                }
              }
            ]
          }
        ),
        type: 'application/ld+json'
      });
    }
  }

  return seoTags;
};
