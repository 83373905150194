


















































































































































































































































import SfBadge from '/storefrontUI/components/components/atoms/SfBadge/SfBadge.vue';
import SfBreadcrumbs from '/storefrontUI/components/components/atoms/SfBreadcrumbs/SfBreadcrumbs.vue';
import SfButton from '/storefrontUI/components/components/atoms/SfButton/SfButton.vue';
import SfCollectedProduct from '/storefrontUI/components/components/organisms/SfCollectedProduct/SfCollectedProduct.vue';
import SfHeading from '/storefrontUI/components/components/atoms/SfHeading/SfHeading.vue';
import SfImage from '/storefrontUI/components/components/atoms/SfImage/SfImage.vue';
import SfLoader from '/storefrontUI/components/components/atoms/SfLoader/SfLoader.vue';
import SfNotification from '/storefrontUI/components/components/molecules/SfNotification/SfNotification.vue';
import SfPrice from '/storefrontUI/components/components/atoms/SfPrice/SfPrice.vue';
import SfProperty from '/storefrontUI/components/components/atoms/SfProperty/SfProperty.vue';
import SfQuantitySelector from '/storefrontUI/components/components/atoms/SfQuantitySelector/SfQuantitySelector.vue';
import {
  defineComponent,
  ref,
  useRouter,
  useContext, onMounted,
} from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import { Breadcrumb } from '~/modules/catalog/types';
import CouponCode from '../../../components/CouponCode.vue';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import {usePrice} from "~/modules/catalog/pricing/usePrice";
import {getMetaInfo} from "~/helpers/getMetaInfo";

export default defineComponent({
  name: 'CartPage',
  components: {
    SfBreadcrumbs,
    SfLoader,
    SfNotification,
    SfButton,
    SfHeading,
    SfProperty,
    SfPrice,
    SfCollectedProduct,
    SfQuantitySelector,
    SfBadge,
    CouponCode,
    SvgImage,
    SfImage,
  },
  setup() {
    const cartView = useCartView();
    const { localePath, app: { i18n } } = useContext();
    const router = useRouter();

    const breadcrumbs = ref<Breadcrumb[]>([
      {
        text: i18n.t('Home') as string,
        link: localePath('/'),
      },
      {
        text: i18n.t('My Cart') as string,
        link: localePath('/cart'),
      },
    ]);

    const cartMeta = ref(null);
    cartMeta.value = {
        'meta_title' : 'Carrello'
    };

    const handleHomeClick = async () => {
      await router.push(localePath('/'));
    };

    onMounted(async () => {
      let clerkScript = document.createElement('script')
      clerkScript.setAttribute('src', '/non-indexable/clerk.io.js')
      clerkScript.setAttribute('defer', 'defer')
      document.head.appendChild(clerkScript)
    });

    return {
      ...cartView,
      breadcrumbs,
      cartMeta,
      handleHomeClick,
    };
  },
  head() {
    return getMetaInfo(this.cartMeta);
  },
});
